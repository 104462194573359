import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { isValid as isValidCpf } from '@fnando/cpf';

import TextMask from './text_mask';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

class CPFInput extends React.Component {
  state = {
    formatedCPF: '',
    cpf: this.props.value,
  }

  onCPFChange = (event) => {
    const formatedCPF = event.target.value;
    const cpf = event.target.value.replace(/[^\d]/g, '');
    this.setState({ cpf, formatedCPF, error: '' }, () => {
      this.props.onChange({ cpf, formatedCPF });
      if (cpf.length === 11) { this.validateCPF(); }
    });
  }

  validateCPF = () => {
    const { cpf } = this.state ;
    if(isValidCpf(cpf)) {
      this.setState({ loading: true }, () => {
        axios.post('/posto/reserva_rapida/validate_cpf', { user_cpf: cpf })
          .then(({ data: users }) => this.setState( { users, loading: false }, () => this.props.onCPFValidation(users)))
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('something wrong happened', err);
            const message = err.response.status === 404 ? 'Usuário não encontrado' : err.message;
            this.setState({ error: message, loading: false, user: undefined });
          });
      });
    } else {
      this.setState({ error: 'CPF inválido' });
    }
  }

  render() {
    return (
      <React.Fragment>
        <TextField
          value={this.state.cpf}
          onChange={this.onCPFChange}
          InputProps={{
            inputProps: { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/,'-', /\d/, /\d/] },
            inputComponent: TextMask,
          }}
          { ...this.props.inputProps }
        />
        { this.state.loading && <LinearProgress/> }
        { this.state.error &&
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <h3 style={{ color: '#c73b3b' }}> {this.state.error} </h3>
            </Grid>
          </Grid>
        }
      </React.Fragment>
    );
  }
}

CPFInput.propTypes = {
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onCPFValidation: PropTypes.func,
};

CPFInput.defaultProps = {
  inputProps: {},
  onChange: () => {},
  onCPFValidation: () => {},
};

export default CPFInput;
