import React, { Fragment } from "react";
import PropTypes from "prop-types";
import qrcodeLogo from "images/qr-icon.jpg";
import Instascan from "instascan-last";

class QRCodeReader extends React.Component {
  state = {
    noCamera: false,
    scanning: false,
    token: "",
    currentCamera: undefined,
  };

  componentDidMount() {
    if (this.props.scanningOnStart) {
      this.handleLoadCamera();
    }
  }

  componentWillUnmount() {
    if (this.scanner && this.state.currentCamera) {
      this.scanner.stop(this.state.currentCamera);
    }
  }

  handleLoadCamera() {
    this.scanner = new Instascan.Scanner({
      video: this.video,
      backgroundScan: false,
      scanPeriod: 2,
    });

    this.scanner.addListener("scan", (token) => {
      this.audio.play();
      this.props.onTokenValidation({ token });
      this.scanner.stop(this.state.currentCamera);
    });

    Instascan.Camera.getCameras()
      .then((cameras) => {
        if (cameras.length > 0) {
          this.setState({ scanning: true, currentCamera: cameras[0] }, () => {
            this.props.onStartScanning();
            this.scanner.start(this.state.currentCamera);
          });
        } else {
          console.error("No cameras found.");
          this.props.onFailing();
        }
      })
      .catch((e) => {
        console.error("Error processing cameras", e);
        this.props.onFailing();
      });
  }

  render() {
    const styleVideo = { display: this.state.scanning ? "block" : "none" };
    const styleImage = { display: this.state.scanning ? "none" : "block" };
    return (
      <Fragment>
        <div className="check-users__qr-code js-check-users__qr-code">
          <video
            ref={(video) => {
              this.video = video;
            }}
            className="check-users__qr-code-preview vsc-initialized"
            width={this.props.videoWidth}
            style={styleVideo}
          />

          <audio
            ref={(audio) => {
              this.audio = audio;
            }}
            autobuffer="true"
            id="audio-beep"
            className="check-users__audio-beep"
            src="/audios/beep.wav"
          />

          {!this.props.scanningOnStart && (
            <a
              style={styleImage}
              onClick={this.handleLoadCamera.bind(this)}
              href="#user-data"
            >
              <img src={qrcodeLogo} className="qr-trigger" />
            </a>
          )}
        </div>

        {!this.props.scanningOnStart && (
          <p style={styleImage} className="check-users__help-text">
            <a
              onClick={this.handleLoadCamera.bind(this)}
              className="button--primary button--wide"
              href="#user-data"
            >
              Validar Qr Code
            </a>
          </p>
        )}
      </Fragment>
    );
  }
}

QRCodeReader.propTypes = {
  scanningOnStart: PropTypes.bool,
  onTokenValidation: PropTypes.func.isRequired,
  onFailing: PropTypes.func,
  onStartScanning: PropTypes.func,
  videoWidth: PropTypes.string,
};

QRCodeReader.defaultProps = {
  videoWidth: "720",
  scanningOnStart: false,
  onFailing: () => {},
  onStartScanning: () => {},
};

export default QRCodeReader;
