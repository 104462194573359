import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TakeoutDialogConfirmation from './TakeoutDialogConfirmation';
import moment from 'moment';
import currency from 'currency.js';
import axios from 'axios';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
export default class Banner extends React.Component {
  state = {
    panelState: false,
    confimationDialogOpen: false,
    clientSelected: null,
    pendingTakeOuts: [],
  };

  componentDidMount() {
    this.pullProductsPickups().then(() => {
      this.interval = setInterval(this.pullProductsPickups.bind(this), 15000 /** 5 seconds */);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  pullProductsPickups() {
    return axios
      .get('/posto/product_pickups')
      .then((res) => {
        const pendingTakeOuts = res.data.pickups.map((item) => {
          const exists = this.state.pendingTakeOuts.find((i) => i.id === item.id);
          if (!exists) {
            item.color = this.generateColor();
            return item;
          }
          return exists;
        });

        const panelState = pendingTakeOuts.length === 0 ? false : this.state.panelState;
        this.setState({ pendingTakeOuts, panelState });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Houve um erro durante o processamento', err);
        const message = err.response.data.errors ? err.response.data.errors.price.join(', ') : err.message;
        this.setState({ processing: false, error: message });
      });
  }

  generateColor() {
    return '#' + Math.random().toString(16).substr(-6);
  }

  getInitials(name) {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  selectItem(client) {
    this.setState({ clientSelected: client });
  }

  renderClientsTable() {
    return this.state.pendingTakeOuts.map((client) => {
      const clientSelected = this.state.clientSelected && this.state.clientSelected.id === client.id;
      return (
        <div className={`item ${clientSelected ? 'active' : ''}`} onClick={() => this.selectItem(client)}>
          <Avatar className="banner__take-out__avatar" style={{ backgroundColor: client.color }}>
            {this.getInitials(client.name)}
          </Avatar>
          <div className="label">
            <div>{client.name}</div>
            <div>{client.cpf}</div>
          </div>
        </div>
      );
    });
  }

  openDialog() {
    this.setState({ confimationDialogOpen: true });
  }
  closeConfirmDialog() {
    this.setState({ confimationDialogOpen: false });
  }

  confirmTakeOut(client) {
    const newPendingTakeOuts = this.state.pendingTakeOuts.filter((_client) => client.id !== _client.id);
    this.setState({
      clientSelected: null,
      panelState: newPendingTakeOuts.length === 0 ? false : this.state.panelState,
      pendingTakeOuts: newPendingTakeOuts,
    });
  }

  productsDetails() {
    const client = this.state.clientSelected;
    return (
      <React.Fragment>
        <div className="clients_products">
          <div>{client.name}</div>
          <div className="clients_products__date">{moment().format('DD/MM/YYYY HH:mm')}</div>
        </div>
        <div className="products_list">
          {client.products.map((product) => {
            return (
              <div className="products_list__product">
                <div>{`${product.quantity} ${product.name}`}</div>
                <div> R$ {currency(product.price, { precision: 2, decimal: ',' }).format()}</div>
              </div>
            );
          })}
        </div>
        <div className="products_list_footer">
          <div className="products_list_footer__totals">
            <div>Total</div>
            <div>R$ {currency(_.sumBy(client.products, 'price'), { precision: 2, decimal: ',' }).format()}</div>
          </div>
          <button
            className="mdc-button mdc-button--raised mdc-button--primary company-login-form__submit-button"
            onClick={() => this.openDialog(client)}
            disabled={!this.props.permitPickup}>
            Confirmar Retirada
          </button>
        </div>
      </React.Fragment>
    );
  }

  renderProductsDetails() {
    const noProducts = (
      <span className="clients_container__products_detail__no_client">Selecione um cliente da lista</span>
    );
    return !this.state.clientSelected ? noProducts : this.productsDetails();
  }

  changePanelStatus() {
    this.setState({ panelState: !this.state.panelState });
  }

  render() {
    const hasPendingTakeOuts = !!this.state.pendingTakeOuts.length;
    const activeClass = hasPendingTakeOuts ? 'banner__take-out__active' : 'banner__take-out__inactive';
    const avatarbctiveClass = hasPendingTakeOuts
      ? 'banner__take-out__avatar__active'
      : 'banner__take-out__avatar__inactive';

    return (
      <div className="banner__take-out">
        <ExpansionPanel
          onChange={this.changePanelStatus.bind(this)}
          CollapseProps={{ classes: { container: 'banner__take-out__header_container' } }}
          classes={{ root: activeClass, expanded: 'banner__take-out__expanded' }}
          expanded={this.state.panelState}
          disabled={!hasPendingTakeOuts}>
          <ExpansionPanelSummary
            classes={{
              root: 'banner__take-out__header',
              disabled: activeClass,
              content: `banner__take-out__header_content ${activeClass}`,
            }}
            expandIcon={<ExpandMoreIcon />}>
            <Avatar className={`banner__take-out__avatar ${avatarbctiveClass}`}>
              {this.state.pendingTakeOuts.length}
            </Avatar>{' '}
            Compras aguardando retirada {this.props.permitPickup == false ? "(!!! Sem permissão !!!)" : null}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: 'clients_container' }}>
            <div className="clients_container__list">{this.renderClientsTable()}</div>
            <div className="clients_container__products_detail">{this.renderProductsDetails()}</div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <TakeoutDialogConfirmation
          onClose={this.closeConfirmDialog.bind(this)}
          open={this.state.confimationDialogOpen}
          client={this.state.clientSelected}
          onConfirmation={this.confirmTakeOut.bind(this)}
        />
      </div>
    );
  }
}
