import React from "react";
import _ from "lodash";

import CPFInput from "../form/cpf_input";
import TextMask from "../form/text_mask";

import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Lock from "@material-ui/icons/Lock";
import CalendarToday from "@material-ui/icons/CalendarToday";
import StayCurrentPortrait from "@material-ui/icons/StayCurrentPortrait";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import egasosa_logo from "images/egasosa_logo.svg";
import meuposto_logo from "images/meuposto_logo.svg";
import meuposto_logo_white_version from "images/meuposto_logo-white.svg";
import egasosa_logo_white_version from "images/meuposto_logo-white.svg";

const styles = () => ({
  dividerItem: {
    display: "flex",
    justifyContent: "start",
  },
  divider: {
    width: "50%",
    height: "50px",
    display: "flex",
    borderRight: "1px solid #949494",
    alignItems: "center",
  },
  dividerSpan: {
    color: "#949494",
    fontSize: "15px",
    backgroundColor: "#FFF",
    paddingLeft: "60%",
    width: "50px",
  },
  errorText: {
    color: "#c73b3b",
  },
  imageStepper: {
    verticalAlign: "middle",
    marginBottom: "0.75em",
    marginRight: "10px",
    height: "54px",
    width: "54px",
  },
});

class CheckInCodeForm extends React.Component {
  state = {
    activeStep: 0,
    user: undefined,
    users: [],
    app: undefined,
    allowedApps: [],
    cpf: "",
    formatedCPF: "",
    code: "",
    error: "",
    phoneConfirmation: "",
    yearConfirmation: "",
    monthConfirmation: "",
    dayConfirmation: "",
    fieldValidation: undefined,
  };

  disabled = () => {
    return !(
      this.state.code.length === 6 ||
      this.state.phoneConfirmation.length === 4 ||
      this.state.yearConfirmation.length === 4 ||
      this.state.dayConfirmation.length === 2 ||
      this.state.monthConfirmation.length === 2
    );
  };

  isAppAllowed(app) {
    return this.state.allowedApps.includes(app);
  }

  pickFieldToValidate = () => {
    return _.sample(["birthday", "phone"]);
  };

  pickInputToValidate = (field) => {
    const inputs = {
      birthday: [
        "validation-by-day",
        "validation-by-month",
        "validation-by-year",
      ],
      phone: ["validation-by-phone"],
    };
    return _.sample(inputs[field]);
  };

  fieldValidation = (user) => {
    return _.cond([
      [
        _.matches({ has_birthday: true, has_phone: true }),
        _.constant(this.pickInputToValidate(this.pickFieldToValidate())),
      ],
      [
        _.matches({ has_birthday: true }),
        _.constant(this.pickInputToValidate("birthday")),
      ],
      [
        _.matches({ has_phone: true }),
        _.constant(this.pickInputToValidate("phone")),
      ],
    ])(user);
  };

  onChangeApp = (app) => {
    const user = _.find(this.state.users, ({ app: _app }) => _app === app);
    this.setState({ app, activeStep: 2, user }, () => {
      this.setState({ fieldValidation: this.fieldValidation(user) });
    });
  };

  fieldTextsValidators = (field) =>
    ({
      "validation-by-day": (
        <TextField
          id="validation-by-day"
          className="login-card__textfield"
          value={this.state.dayConfirmation}
          onChange={({ target }) =>
            this.setState({
              dayConfirmation: target.value.replace(/[^\d]/g, ""),
            })
          }
          label="Dia Nascimento"
          placeholder="2 digitos"
          maxlenght="2"
          variant="outlined"
          fullWidth
          InputProps={{
            inputProps: { mask: [/\d/, /\d/] },
            inputComponent: TextMask,
            startAdornment: (
              <InputAdornment position="start">
                <CalendarToday />
              </InputAdornment>
            ),
          }}
        />
      ),
      "validation-by-month": (
        <TextField
          id="validation-by-month"
          className="login-card__textfield"
          value={this.state.monthConfirmation}
          onChange={({ target }) =>
            this.setState({
              monthConfirmation: target.value.replace(/[^\d]/g, ""),
            })
          }
          label="Mês Nascimento"
          placeholder="2 digitos"
          maxlenght="2"
          variant="outlined"
          fullWidth
          InputProps={{
            inputProps: { mask: [/\d/, /\d/] },
            inputComponent: TextMask,
            startAdornment: (
              <InputAdornment position="start">
                <CalendarToday />
              </InputAdornment>
            ),
          }}
        />
      ),
      "validation-by-year": (
        <TextField
          id="validation-by-year"
          className="login-card__textfield"
          value={this.state.yearConfirmation}
          onChange={({ target }) =>
            this.setState({
              yearConfirmation: target.value.replace(/[^\d]/g, ""),
            })
          }
          label="Ano Nascimento"
          placeholder="4 digitos"
          maxlenght="4"
          variant="outlined"
          fullWidth
          InputProps={{
            inputProps: { mask: [/\d/, /\d/, /\d/, /\d/] },
            inputComponent: TextMask,
            startAdornment: (
              <InputAdornment position="start">
                <CalendarToday />
              </InputAdornment>
            ),
          }}
        />
      ),
      "validation-by-phone": (
        <TextField
          id="validation-by-phone"
          className="login-card__textfield"
          value={this.state.phoneConfirmation}
          onChange={({ target }) =>
            this.setState({
              phoneConfirmation: target.value.replace(/[^\d]/g, ""),
            })
          }
          label="Últimos 4 dígitos do celular"
          maxlenght="15"
          variant="outlined"
          placeholder="4 ultimos números"
          fullWidth
          InputProps={{
            inputProps: { mask: [/\d/, /\d/, /\d/, /\d/] },
            inputComponent: TextMask,
            startAdornment: (
              <InputAdornment position="start">
                <StayCurrentPortrait />
              </InputAdornment>
            ),
          }}
        />
      ),
    }[field]);

  onCPFChange = ({ cpf, formatedCPF }) => {
    this.setState({ cpf, formatedCPF });
  };

  onCPFValidation = (users) => {
    let activeStep = 1;
    let allowedApps = this.state.allowedApps;
    let error = "";

    if (users.length) {
      allowedApps = _.intersection(
        this.props.company.enabled_apps,
        _.map(users, "app")
      );
    }

    if (!users.length || _.isEmpty(allowedApps)) {
      activeStep = 0;
      error = "Usuário não encontrado";
    }

    this.setState({ users, activeStep, error, allowedApps }, () => {
      if (allowedApps.length === 1) {
        const app = _.head(allowedApps);
        this.onChangeApp(app);
      }
    });
  };

  render() {
    return (
      <div
        className="check-users__form-container js-use-data-container"
        id="user-data"
      >
        <form
          autoComplete="off"
          className="simple_form new_check_user"
          id="new_check_user"
        >
          <div className="mdc-card">
            <section className="mdc-card__primary">
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
              >
                <Step key="clients-crednetials">
                  {" "}
                  // Cpf form
                  <StepLabel>
                    {!this.state.users.length ? (
                      "Digite dados do Cliente"
                    ) : (
                      <div>
                        {_.get(this.state.users, "0.name")}
                        <br />
                        {this.state.formatedCPF}
                      </div>
                    )}
                  </StepLabel>
                  <StepContent>
                    <CPFInput
                      value={this.state.cpf}
                      onChange={this.onCPFChange}
                      onCPFValidation={this.onCPFValidation}
                      inputProps={{
                        fullWidth: true,
                        disabled: this.state.loading,
                        style: { marginTop: 16, marginBottom: 30 },
                        id: "cpf",
                        label: "CPF",
                      }}
                    />
                    {this.state.error && (
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                          <h3 className={this.props.classes.errorText}>
                            {" "}
                            {this.state.error}{" "}
                          </h3>
                        </Grid>
                      </Grid>
                    )}
                  </StepContent>
                </Step>
                <Step key="app-selection">
                  {" "}
                  // app selection
                  <StepLabel>
                    {this.state.app ? (
                      <div>
                        <img
                          src={
                            this.state.app === "egasosa"
                              ? egasosa_logo
                              : meuposto_logo
                          }
                          className={this.props.classes.imageStepper}
                        />
                        {this.state.app === "egasosa"
                          ? "E-gasosa"
                          : "Meu Posto"}
                      </div>
                    ) : (
                      "Escolha o App"
                    )}
                  </StepLabel>
                  <StepContent>
                    <FormControlLabel
                      control={
                        <Radio
                          id="app-egasosa"
                          checked={this.state.app === "egasosa"}
                          disabled={!this.isAppAllowed("egasosa")}
                          disableRipple={true}
                          onChange={({ target }) =>
                            this.onChangeApp(target.value)
                          }
                          icon={
                            <img
                              src={egasosa_logo}
                              alt="egasosa"
                              height="86"
                              width="86"
                            />
                          }
                          checkedIcon={
                            <img
                              src={egasosa_logo_white_version}
                              alt="egasosa"
                              height="86"
                              width="86"
                            />
                          }
                          value="egasosa"
                          name="app"
                        />
                      }
                      label="E-Gasosa"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          id="app-meu_posto"
                          checked={this.state.app === "meu_posto"}
                          disabled={!this.isAppAllowed("meu_posto")}
                          disableRipple={true}
                          onChange={({ target }) =>
                            this.onChangeApp(target.value)
                          }
                          icon={
                            <img
                              src={meuposto_logo}
                              alt="Meu Posto"
                              height="86"
                              width="86"
                            />
                          }
                          checkedIcon={
                            <img
                              src={meuposto_logo_white_version}
                              alt="Meu Posto"
                              height="86"
                              width="86"
                            />
                          }
                          value="meu_posto"
                          name="app"
                        />
                      }
                      label="Meu Posto"
                    />
                  </StepContent>
                </Step>
                <Step key="app-selection">
                  {" "}
                  // user validation
                  <StepLabel>Valide o usuário</StepLabel>
                  <StepContent>
                    {this.state.user && (
                      <Grid container spacing={0} justify="space-evenly">
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="security_code"
                            className="login-card__textfield"
                            value={this.state.code}
                            onChange={({ target }) =>
                              this.setState({
                                code: target.value.replace(/[^\d]/g, ""),
                              })
                            }
                            label="Código de Validação do APP"
                            style={{ marginBottom: 15 }}
                            fullWidth
                            maxlenght="6"
                            variant="outlined"
                            InputProps={{
                              inputProps: {
                                mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                              },
                              inputComponent: TextMask,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {this.fieldTextsValidators(
                            this.state.fieldValidation
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </StepContent>
                </Step>
              </Stepper>
            </section>
          </div>
          {this.state.user && (
            <input
              type="button"
              disabled={this.disabled()}
              onClick={() => this.props.onFormFilledIn(this.state)}
              value="Validar Usuário"
              className="btn button--primary button--wide button--margin-top"
            />
          )}
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(CheckInCodeForm);
