import React from 'react';
import MaskedInput from 'react-text-mask';

export default (props) => {
  const { inputRef, mask, style, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={mask}
      placeholderChar={'\u2000'}
      style={style}
    />
  );
};
