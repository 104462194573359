import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import currency from 'currency.js';
import axios from 'axios';

export default class TakeoutDialogConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dialogConfirmationOpen: props.open || false,
      error: '',
    };
  }

  handleOnConfirmation = () => {
    this.setState({ loading: true }, () => {
      const { client } = this.props;
      axios
        .post('/posto/product_pickups', { id: client.id, payment_ids: _.map(client.products, 'payment_id') })
        .then(() => {
          this.setState({ loading: false, dialogConfirmationOpen: false, error: '' });
          this.props.onClose();
          this.props.onConfirmation(this.props.client);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Houve um erro durante o processamento', err);
          const message = err.response.data.errors ? err.response.data.errors.price.join(', ') : err.message;
          this.setState({ loading: false, dialogConfirmationOpen: false, error: '' });
          this.props.onClose();
        });
      // axios
      //   .post(this.props.confirmationPath, data)
      //   .then((res) => {
      //     // this.setState({ loading: false, dialogConfirmationOpen: false, error: '' });
      //     // this.props.handleReservationConfirmation(res.data.transaction_validator);
      //     // this.props.history.push(`${this.props.basePath}/summary`);
      //   })
      //   .catch((err) => {
      //     // this.setState({ loading: false, error: err.message });
      //     // console.log('Houve um erro durante o processamento', err);
      //   });
    });
  };

  handleCloseDialogConfirmation = () => {
    this.setState({ dialogConfirmationOpen: false });
  };

  renderProcessingPayment = () => {
    return (
      <React.Fragment>
        <h2 className="mui-dialog_body__title">Processando...</h2>
        <CircularProgress size={80} />
      </React.Fragment>
    );
  };

  /*
   *    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px;
   */
  renderTableConfirmation = () => {
    return (
      this.props.client && (
        <div className="banner__take-out__dialogconfirmation">
          <div>
            {this.props.client.name} - [{this.props.client.cpf}]
          </div>
          <div>
            R$ {currency(_.sumBy(this.props.client.products, 'price'), { precision: 2, decimal: ',' }).format()}
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open} >
        <DialogTitle className="mui-dialog__title">Confirmação retirada de produtos</DialogTitle>
        <DialogContent className="mui-dialog__body mui-dialog__body-fulll-width">
          {this.state.loading ? this.renderProcessingPayment() : this.renderTableConfirmation()}
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.loading} onClick={this.props.onClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={this.state.loading} onClick={this.handleOnConfirmation.bind(this)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
