import React from 'react';
import QRCodeReader from './QRCodeReader';
import CheckInCodeForm from './CheckInCodeForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import numeral from 'numeral';
import axios from 'axios';

import TakeoutBanner from '../takeout/Banner';

export default class CheckInUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productPrices: {},
      processing: true,
      scanning: false,
      cantScanning: this.props.skipCameraUsage,
      cpf: undefined,
      code: undefined,
      token: undefined,
      currentPrices: [],
    };
  }

  componentDidMount() {
    this.setState({ processing: true }, () => {
      axios
        .get(this.props.newPriceUpdatePath)
        .then((res) => {
          const productPrices = res.data;
          this.setState({ productPrices }, () => {
            const currentPrices = productPrices.products.reduce((acc, product) => {
              const { price, date } = this.lastUpdateForProduct(product.id);
              acc.push({ product, price, date });
              return acc;
            }, []);
            this.setState({ currentPrices, processing: false });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Houve um erro durante o processamento', err);
          const message = err.response.data.errors ? err.response.data.errors.price.join(', ') : err.message;
          this.setState({ processing: false, error: message });
        });
    });
  }

  lastUpdateForProduct = (productId) => {
    const date = Object.keys(this.state.productPrices.prices)
      .map((d) => ({ formated: moment(d), key: d }))
      .sort((a, b) => b.formated - a.formated)[0];

    const product = this.state.productPrices.prices[date.key].find((p) => p.product_id === productId) || {};
    return {
      date: date.formated,
      price: product.price || 0,
    };
  };

  handleScanningFail = () => {
    this.setState({ cantScanning: true, scanning: false });
  };

  handleProcessCheckIn = ({
    code,
    cpf,
    token,
    phoneConfirmation,
    yearConfirmation,
    monthConfirmation,
    dayConfirmation,
    app,
  }) => {
    const newState = {
      scanning: false,
      processing: true,
      app,
      cpf,
      code,
      token,
      month_date_of_birth: monthConfirmation,
      day_date_of_birth: dayConfirmation,
      year_date_of_birth: yearConfirmation,
      last_four_digits_phone: phoneConfirmation,
    };
    this.setState(newState, () => {
      // eslint-disable-next-line camelcase
      const { month_date_of_birth, day_date_of_birth, year_date_of_birth, last_four_digits_phone } = this.state;
      const body = {
        check_user: {
          qrcode_token: this.state.token,
          cpf: this.state.cpf,
          app: this.state.app,
          month_date_of_birth,
          day_date_of_birth,
          year_date_of_birth,
          last_four_digits_phone,
        },
      };

      axios
        .post(this.props.companyFastReservationCheckInPath, body)
        .then((res) => {
          this.setState({ processing: false });
          this.props.handleCheckInUser(res.data);
          this.props.history.push(`${this.props.basePath}/select_products`);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Houve um erro durante o processamento', err, err.response);
          const message = err.response.data.error ? err.response.data.error : err.message;
          this.setState({ processing: false, error: message });
        });
    });
  };

  renderProcessingChecking = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  handleUpdateProduct = () => {
    this.props.history.push(`${this.props.basePath}/update_price`);
  };

  handleBackToQRCode = () => {
    this.setState({ cantScanning: false });
  };

  render() {
    return (
      <div className="container check-users">
        <TakeoutBanner permitPickup={this.props.frontUserPermitPickupConfirmation} />
        <h1 className="main-header">
          {this.state.cantScanning ? (
            <div className="title">VALIDAÇÃO POR CPF</div>
          ) : (
            <div className="title">VALIDE O QR CODE DO CLIENTE:</div>
          )}
        </h1>
        {this.state.error && <h4 class="check-users__qrcode-error-message">{this.state.error}</h4>}
        {this.state.processing ? (
          this.renderProcessingChecking()
        ) : (
          <React.Fragment>
            <div className="check-users__container">
              {this.state.cantScanning ? (
                <CheckInCodeForm
                  company={this.props.company}
                  onFormFilledIn={this.handleProcessCheckIn}
                  navigateToQRCode={this.handleBackToQRCode}
                />
              ) : (
                <React.Fragment>
                  <QRCodeReader
                    key={1}
                    onStartScanning={(_) => this.setState({ scanning: true })}
                    onFailing={this.handleScanningFail}
                    onTokenValidation={this.handleProcessCheckIn}
                  />
                  <div key={2} className="js-valid-code">
                    <h2 style={{ display: this.state.scanning ? 'block' : 'none' }}> O QR CODE NÃO FUNCIONOU?</h2>
                    <a onClick={this.handleScanningFail} className="button--primary button--wide" href="#user-data">
                      {' '}
                      Validar através do CPF
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="check-users__product_prices">
              {this.state.currentPrices
                .sort((a, b) => a.product.position - b.product.position)
                .map((current, index) => (
                  <span key={index}>
                    {current.product.name}
                    <br />
                    {numeral(parseFloat(current.price)).format('0.000')}
                  </span>
                ))}
            </div>
            {this.state.currentPrices[0] && (
              <div className="check-users__options">
                <p>ATUALIZADO {this.state.currentPrices[0].date.format('DD/MM/YYYY hh:mm')}</p>
                <ul>
                  <li className="refresh">
                    <a href="#" onClick={this.handleUpdateProduct}>
                      Atualizar Preços de Bomba
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
